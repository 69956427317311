import * as React from "react";
import styled from "styled-components";

// import styled from "styled-components"
import Layout from "../components/layout";
import Quote from "../components/quote";

import MainWrapper from "../components/siteWrapper";
import Books from "../images/booksBackground.jpg";

export function Head( {location} ) {

  const title = "Who are the Freemasons?";
  const description = "A quick 'About The Freemasons' to take a look through";
  const siteURL = "https://newwestfreemasons.ca"

  return (
      <>
        <title>
          {title}
        </title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Perfection Lodge No. 75, New Westminster" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="ca_EN" />
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content={`${siteURL}${location.pathname}/`}
        />
        <meta property="og:image" content="https://newwestfreemasons.ca/logo.png" />
        <link rel="canonical" href={`${siteURL}${location.pathname}/`} />
      </>
  )
}

const HeroDesktop = styled.div`
  background: url(${Books}) center;
  height: 400px;
  width: 100%;
`;

const WhoWeArePage = () => (
  <Layout>
    <HeroDesktop />

    <MainWrapper>
      {/* introduction and hero */}
      <Quote>
        <p>
          “There is nothing noble being superior to your fellow man; true
          nobility is being superior to your former self.”
        </p>
        <p>~W. Bro.Winston Churchill</p>
      </Quote>

      {/* main start */}

      <div>
        <h1>Who are the Freemasons, what attracts a man to Masonry, and an Ideal.</h1>
        <h2>What attracts a man to Freemasonry</h2>
        <p>
          Every man comes of his own free will and accord, with his own
          individual needs and interests. One man may join so that he can
          associate with other men who believe that only by improving themselves
          can they hope to improve the world. Another man may join because he is
          looking for a focus for his charitable inclinations. And yet another
          may be attracted by a strong sense of history and tradition. Many join
          simply because they knew a friend or relative who was a Freemason and
          they admired that man’s way of living his life.
        </p>
        <p>
          All who join and become active discover a bond of brotherly affection
          and a community of mutual support; a practical extension of their own
          religious and social beliefs.
        </p>
        <p>
          Please read the Ideal of a Freemason (below) in the What Is Freemasonry page.
          It was written over a hundred years ago and sums up much of what
          attracts some to Freemasonry.
        </p>
        <p>
          Keep in mind that this is an ideal. This is a goal that some
          Freemasons may fall short of, but they all have committed themselves
          to a path that leads to this ideal. We do not consider ourselves
          superior, but we do hold ourselves to high standards.
        </p>
      </div>
      {/*<div>*/}
      {/*  <h1>Who are Freemasons?</h1>*/}
      {/*  <p>*/}
      {/*    Expressing a desire to join the fraternity is the first step to*/}
      {/*    becoming a Freemason.*/}
      {/*  </p>*/}
      {/*  <p>*/}
      {/*    What attracts a man to Freemasonry? Every man comes of his own free*/}
      {/*    will and accord, with his own individual needs and interests. One man*/}
      {/*    may join so that he can associate with other men who believe that only*/}
      {/*    by improving themselves can they hope to improve the world. Another*/}
      {/*    man may join because he is looking for a focus for his charitable*/}
      {/*    inclinations. And yet another may be attracted by a strong sense of*/}
      {/*    history and tradition. Many join simply because they knew a friend or*/}
      {/*    relative who was a Freemason and they admired that man’s way of living*/}
      {/*    his life. All who join and become active discover a bond of brotherly*/}
      {/*    affection and a community of mutual support; a practical extension of*/}
      {/*    their own religious and social beliefs.*/}
      {/*  </p>*/}
      {/*  <p>*/}
      {/*    Please read a short article entitled “*/}
      {/*    <Link to="/ideal-of-a-freemason/" title="ideal of a freemason">*/}
      {/*      The Ideal of a Freemason*/}
      {/*    </Link>*/}
      {/*    “. It was written over a hundred years ago and sums up much of what*/}
      {/*    attracts some to Freemasonry. Keep in mind that this is an ideal. This*/}
      {/*    is a goal that some Freemasons may fall short of, but they all have*/}
      {/*    committed themselves to a path that leads to this ideal. We do not*/}
      {/*    consider ourselves superior, but we do hold ourselves to high*/}
      {/*    standards.*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div>
        <h2>Ideal of a Freemason</h2>
        <p>
          If you see a man who quietly and modestly moves in the sphere of his
          life; who, without blemish, fulfils his duty as a man, a subject, a
          husband and a father; who is pious without hypocrisy, benevolent
          without ostentation, and aids his fellowman without self-interest;
          whose heart beats warm for friendship, whose serene mind is open for
          licensed pleasures, who in vicissitudes does not despair, nor in
          fortune will be presumptuous, and who will be resolute in the hour of
          danger;
        </p>
        <p>
          The man who is free from superstition and free from infidelity; who in
          nature sees the finger of the Eternal Master; who feels and adores the
          higher destination of man; to whom faith, hope and charity are not
          mere words without any meaning; to whom property, nay even life, is
          not too dear for the protection of innocence and virtue, and for the
          defense of truth;
        </p>
        <p>
          The man who towards himself is a severe judge, but who is tolerant
          with the debilities of his neighbour; who endeavours to oppose errors
          without arrogance, and to promote intelligence without impatience; who
          properly understands how to estimate and employ his means; who honours
          virtue though it may be in the most humble garment, and who does not
          favour vice though it be clad in purple; and who administers justice
          to merit whether dwelling in palaces or cottages.
        </p>
        <p>
          The man who, without courting applause, is loved by all noble-minded
          men, respected by his superiors and revered by his subordinates; the
          man who never proclaims what he has done, can do, or will do, but
          where need is will lay hold with dispassionate courage, circumspect
          resolution, indefatigable exertion and a rare power of mind, and who
          will not cease until he has accomplished his work, and then, without
          pretension, will retire into the multitude because he did the good
          act, not for himself, but for the cause of good!
        </p>
        <p>
          If you, my Brethren meet such a man, you will see the personification
          of brotherly love, relief and truth; and you will have found the ideal
          of a Freemason.
        </p>
        <p>
          <i>
            Excerpted from "The History of Freemasonry" by{" "}
            <a
                href="https://www.freemasonry.bcy.ca/biography/klotz_o/klotz_o.html"
                target="_blank"
                rel="noreferrer noopener"
                title="Otto Klotz"
            >
              Otto Klotz
            </a>
            , The Canadian Craftsman, March 15, 1868. M.W. Bro. Otto Klotz was
            an honorary Past Grand Master of the{" "}
            <a
                href="https://grandlodge.on.ca"
                title="Grand Lodge of Canada in the Province of Ontario"
                target="_blank"
                rel="noreferrer noopener"
            >
              Grand Lodge of Canada in the Province of Ontario
            </a>
            .
          </i>
        </p>
      </div>
    </MainWrapper>
  </Layout>
);

export default WhoWeArePage;